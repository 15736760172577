import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import { Tabs, Tab, Image, Icon, Tag } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Icon.name]: Icon,
        [Tag.name]: Tag
    },
    data () {
        return {
            resultTab: 'VEW',
            taskCode: '',
            taskList: [],
            count: { view: 0, sign: 0, admit: 0 },
            page: { current: 0, size: 50 }
        }
    },
    mounted () {
        var query = this.$route.query
        this.taskCode = query.taskCode
        this.retrieveWorkCount()
        this.retrieveTaskBuyPage()
    },
    methods: {
        async retrieveWorkCount () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveUserWorkCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.count.view = res.data.view
                this.count.sign = res.data.sign
                this.count.admit = res.data.admit
            }
        },
        tabChange (val) {
            this.resultTab = val
            this.page.current = 0
            this.taskList = []
            if (val === 'VEW') {
                this.retrieveTaskBuyPage()
            } else if (val === 'SNG') {
                this.retrieveTaskSignPage('')
            } else if (val === 'AMT') {
                this.retrieveTaskSignPage('PAS')
            }
        },
        async retrieveTaskBuyPage () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, current: this.page.current, size: this.page.size }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveUserTaskBuyPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.taskList = res.data
            }
        },
        async retrieveTaskSignPage (result) {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, result: result, current: this.page.current, size: this.page.size }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveUserTaskSignPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.taskList = res.data
            }
        },
        orderProcess (orderCode) {
            if (this.activeTab === 'RCT') {
                this.$router.push({ path: '/recruit/orderProcess', query: { orderCode: orderCode } })
            } else if (this.activeTab === 'TRA') {
                this.$router.push({ path: '/train/orderProcess', query: { orderCode: orderCode } })
            } else if (this.activeTab === 'ERO') {
                this.$router.push({ path: '/enroll/orderProcess', query: { orderCode: orderCode } })
            }
        },
        async updateResult (result) {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { taskCode: this.taskCode, userCode: user, result: result }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskRLS/updateSignResult', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '更新成功'
                }).then(() => {
                    this.retrieveSignPage()
                })
            }
        },
        detail (code) {
            this.$router.push({ path: '/mde/hire/detail', query: { taskCode: code } })
        },
        cellphone (phone) {
            window.location.href = 'tel:' + phone
        }
    }
}
