<template>
  <van-row>
    <van-col span="24">
      <van-tabbar
        v-model="activeTab"
        :active-color="COLOR_M"
        inactive-color="#000"
      >
        <!-- <van-tabbar-item
          name="home"
          v-if="activeTab === 'home'"
          icon="home-o"
          @click="gather()"
          >首页</van-tabbar-item
        > -->
        <van-tabbar-item name="home" icon="home-o" @click="home()"
          >首页</van-tabbar-item
        >
        <van-tabbar-item name="work" icon="notes-o" @click="work()"
          >我的工作</van-tabbar-item
        >
        <van-tabbar-item name="seek" icon="aim" @click="seek()"
          >找工作</van-tabbar-item
        >
        <van-tabbar-item name="publish" icon="friends-o" @click="publish()"
          >找工人</van-tabbar-item
        >
        <van-tabbar-item name="settle" icon="gift-o" @click="settle()"
          >我的分享</van-tabbar-item
        >
      </van-tabbar>
    </van-col>
  </van-row>
</template>
<script>
export default {
  data () {
    var at = this.active
    return {
      activeTab: at
    }
  },
  props: { active: { type: String } },
  mounted () {
  },
  methods: {
    home () {
      this.$router.push({ path: '/mde/hire/home' })
    },
    work () {
      this.$router.push({ path: '/mde/hire/work' })
    },
    seek () {
      this.$router.push({ path: '/mde/hire/seek' })
    },
    publish () {
      this.$router.push({ path: '/mde/hire/publish' })
    },
    settle () {
      this.$router.push({ path: '/mde/hire/settle' })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
