<template>
  <div class="page">
    <Navbar title="推广订单" type="SMP" />
    <van-row class="count">
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-m">{{ count.view }}次</div>
        <div class="text">已付费数量</div></van-col
      >
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-s1">{{ count.sign }}个</div>
        <div class="text">已报名数量</div></van-col
      >
      <van-col span="8"
        ><div class="amount color-s2">{{ count.admit }}个</div>
        <div class="text">已录用数量</div></van-col
      >
    </van-row>
    <van-tabs
      :active="resultTab"
      border="true"
      :color="COLOR"
      @change="tabChange"
    >
      <van-tab title="已付费" name="VEW">
        <div class="task-list">
          <van-row
            class="task bdc-b1s-gray"
            v-for="task in taskList"
            :key="task.code"
          >
            <van-col span="24" class="content">
              <van-row>
                <van-col span="24" class="name text-short"
                  ><van-tag :color="COLOR_M" v-if="task.state === 'NML'"
                    >进行中</van-tag
                  ><van-tag :color="COLOR_S1" v-if="task.state === 'CMP'"
                    >已招满</van-tag
                  >
                  {{ task.name }}</van-col
                >
              </van-row>
              <van-row>
                <van-col span="24" class="label">
                  <span
                    v-for="item in task.labels"
                    :key="item.name"
                    class="item bgc-gray"
                    >{{ item.name }}</span
                  >
                </van-col>
              </van-row>
              <van-row>
                <van-col span="14" class="time"
                  ><van-icon name="underway-o" />&nbsp;{{ task.createTime }}
                </van-col>
                <van-col span="10" class="salary fc-cyan"
                  ><span v-if="task.salaryMin > 1000"
                    >{{ (task.salaryMin / 1000).toFixed(1) }}K-{{
                      (task.salaryMax / 1000).toFixed(1)
                    }}K</span
                  ><span v-if="task.salaryMin < 1000"
                    >{{ task.salaryMin }}-{{ task.salaryMax }}</span
                  >/<span v-if="task.salaryType === 'YER'">年</span>
                  <span v-if="task.salaryType === 'MOT'">月</span>
                  <span v-if="task.salaryType === 'DAY'">日</span></van-col
                >
              </van-row>
              <van-row>
                <van-col :span="18" class="company">
                  <span
                    ><van-icon name="newspaper-o" />&nbsp;{{ task.supplier }}
                    <van-tag
                      :color="COLOR_S2"
                      v-if="task.supplierType === 'DRT'"
                      >直营</van-tag
                    ></span
                  ></van-col
                >
                <van-col span="6" class="amount"
                  ><van-icon name="friends-o" />&nbsp;{{
                    task.count
                  }}人</van-col
                >
              </van-row>
              <van-row class="btn">
                <van-col span="24"
                  ><van-button
                    :color="COLOR_M"
                    icon="eye-o"
                    size="mini"
                    v-if="task.state === 'PCS'"
                    @click="detail(task.code)"
                    >查看明细</van-button
                  >&nbsp;&nbsp;<van-button
                    :color="COLOR_S1"
                    icon="refund-o"
                    size="mini"
                    @click="refund(task.code)"
                    >申请退费</van-button
                  >&nbsp;&nbsp;<van-button
                    :color="COLOR_S2"
                    icon="search"
                    size="mini"
                    v-if="task.state === 'PCS'"
                    @click="similar(task.code)"
                    >相似岗位</van-button
                  ></van-col
                >
              </van-row>
              <!-- <van-row>
            <van-col :span="18" class="address">
              <van-icon name="location-o" />&nbsp;{{ task.address }}</van-col
            >
            <van-col :span="6" class="distance"
              >距我{{ task.distance }} KM</van-col
            >
          </van-row> -->
            </van-col>
          </van-row>
          <van-row v-if="taskList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </div>
      </van-tab>
      <van-tab title="已报名" name="SNG"> </van-tab>
      <van-tab title="已录用" name="AMT"> </van-tab>
    </van-tabs>
    <Tabbar active="work" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
